<template>
  <b-modal id="vendor-user-modal" hide-footer centered>
    <div v-if="user">
      <b-form-group
        v-slot="{ ariaDescribedby }"
        :label="$i18n.t('users.assignRoles')"
      >
        <b-form-checkbox-group
          v-model="selected"
          :options="options"
          :aria-describedby="ariaDescribedby"
          name="flavour-2a"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>
    </div>
    <b-button
      variant="outline-success"
      pill
      size="sm"
      @click="submitUserBranches"
    >
      {{ $i18n.t("global.submit") }}
    </b-button>
  </b-modal>
</template>

<script>
import { BModal, BFormGroup, BFormCheckboxGroup, BButton } from "bootstrap-vue";
import { mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "VendorUserAssignModal",
  components: {
    BModal,
    BFormGroup,
    BFormCheckboxGroup,
    BButton,
  },
  props: {
    user: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      options: null,
      selected: [],
    };
  },
  watch: {
    user(newVal) {
      this.selected = []
      this.options = newVal.map((e) => ({
        value: e.branch.id,
        text: `${e.branch.nameGeo} / ${e.branch.nameEng}`,
      }));
      newVal.forEach((e) => {
        if (e.isAssigned) {
          this.selected.push(e.branch.id);
        }
      });
    },
  },
  methods: {
    ...mapActions("roleManagement", ["setUserBranches", "getAdmins"]),
    submitUserBranches() {
      const payload = {
        id: this.id,
        branches: this.selected,
      };
      this.setUserBranches(payload).then(() => {
        this.$bvModal.hide("vendor-user-modal");
        Swal.fire({
          text: "User updated",
          icon: "success",
          confirmButtonText: "Continue",
        });
        this.selected = [];
        this.getAdmins();
      });
    },
  },
};
</script>

<style scoped></style>
