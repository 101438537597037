<template>
  <b-card
    :header="$i18n.t('navigation.vendor-users')"
    header-bg-variant="primary"
    header-text-variant="white"
    class="checkboxWrapper"
  >
    <VendorUserAssignModal :id="popupUserId" :user="popupUser" />
    <b-table
      sticky-header
      responsive
      bordered
      class="products-table shadow-sm rounded"
      :items="admins"
      show-empty
      :fields="fields"
      hover
      small
      @row-clicked="onRowClick"
    >
      <template #cell(action)="data">
        <b-button
          variant="flat-primary"
          class="btn-icon"
          @click="onRowClick(data.item)"
        >
          <feather-icon size="16" icon="EditIcon" />
        </b-button>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BTable, BCard, BButton } from "bootstrap-vue";
import VendorUserAssignModal from "@/views/role-management/components/VendorUserAssignModal.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BTable,
    BCard,
    BButton,
    VendorUserAssignModal,
  },
  data() {
    return {
      popupUser: null,
      t: null,
      popupUserId: null,
    };
  },
  computed: {
    ...mapState("roleManagement", ["userRoles", "userBranches", "admins"]),
    fields() {
      return [
        {
          key: "action",
          label: this.$i18n.t("global.action"),
        },
        {
          key: "id",
          label: "Id",
        },
        {
          key: "userName",
          label: this.$i18n.t("roleManagement.username"),
        },
        {
          key: "email",
          label: this.$i18n.t("roleManagement.email"),
        },
        {
          key: "mobile",
          label: this.$i18n.t("roleManagement.phone"),
        },
      ];
    },
  },
  created() {
    this.t = this.$i18n;
    this.getAdmins().then(() => {
      console.log(this.admins);
    });
  },
  methods: {
    ...mapActions("roleManagement", ["getAdmins", "getUserBranches"]),
    onRowClick(user) {
      this.getUserBranches(user.id).then(() => {
        this.popupUserId = user.id;
        this.popupUser = this.userBranches;
        this.$bvModal.show("vendor-user-modal");
      });
    },
  },
};
</script>
